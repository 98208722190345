import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Rome - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/rome/"
      image="https://kiwicacahuate.com/rome/rome.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/rome.webp, /rome/rome.webp 2x" />
                <source srcSet="/rome/rome.jpg, /rome/rome.jpg 2x" />
                <img src="/rome/rome.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/001.webp, /rome/thumbnail/001-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/001.jpg, /rome/thumbnail/001-2x.jpg 2x" />
                <img src="/rome/thumbnail/001.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/002.webp, /rome/thumbnail/002-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/002.jpg, /rome/thumbnail/002-2x.jpg 2x" />
                <img src="/rome/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/003.webp, /rome/thumbnail/003-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/003.jpg, /rome/thumbnail/003-2x.jpg 2x" />
                <img src="/rome/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/004.webp, /rome/thumbnail/004-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/004.jpg, /rome/thumbnail/004-2x.jpg 2x" />
                <img src="/rome/thumbnail/004.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/005.webp, /rome/thumbnail/005-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/005.jpg, /rome/thumbnail/005-2x.jpg 2x" />
                <img src="/rome/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/006.webp, /rome/thumbnail/006-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/006.jpg, /rome/thumbnail/006-2x.jpg 2x" />
                <img src="/rome/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/007.webp, /rome/thumbnail/007-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/007.jpg, /rome/thumbnail/007-2x.jpg 2x" />
                <img src="/rome/thumbnail/007.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/008.webp, /rome/thumbnail/008-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/008.jpg, /rome/thumbnail/008-2x.jpg 2x" />
                <img src="/rome/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/009.webp, /rome/thumbnail/009-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/009.jpg, /rome/thumbnail/009-2x.jpg 2x" />
                <img src="/rome/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/010.webp, /rome/thumbnail/010-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/010.jpg, /rome/thumbnail/010-2x.jpg 2x" />
                <img src="/rome/thumbnail/010.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/011.webp, /rome/thumbnail/011-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/011.jpg, /rome/thumbnail/011-2x.jpg 2x" />
                <img src="/rome/thumbnail/011.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/012.webp, /rome/thumbnail/012-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/012.jpg, /rome/thumbnail/012-2x.jpg 2x" />
                <img src="/rome/thumbnail/012.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/013.webp, /rome/thumbnail/013-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/013.jpg, /rome/thumbnail/013-2x.jpg 2x" />
                <img src="/rome/thumbnail/013.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/014.webp, /rome/thumbnail/014-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/014.jpg, /rome/thumbnail/014-2x.jpg 2x" />
                <img src="/rome/thumbnail/014.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/015.webp, /rome/thumbnail/015-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/015.jpg, /rome/thumbnail/015-2x.jpg 2x" />
                <img src="/rome/thumbnail/015.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/016.webp, /rome/thumbnail/016-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/016.jpg, /rome/thumbnail/016-2x.jpg 2x" />
                <img src="/rome/thumbnail/016.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/018.webp, /rome/thumbnail/018-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/018.jpg, /rome/thumbnail/018-2x.jpg 2x" />
                <img src="/rome/thumbnail/018.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/019.webp, /rome/thumbnail/019-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/019.jpg, /rome/thumbnail/019-2x.jpg 2x" />
                <img src="/rome/thumbnail/019.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/020.webp, /rome/thumbnail/020-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/020.jpg, /rome/thumbnail/020-2x.jpg 2x" />
                <img src="/rome/thumbnail/020.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/021.webp, /rome/thumbnail/021-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/021.jpg, /rome/thumbnail/021-2x.jpg 2x" />
                <img src="/rome/thumbnail/021.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/022.webp, /rome/thumbnail/022-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/022.jpg, /rome/thumbnail/022-2x.jpg 2x" />
                <img src="/rome/thumbnail/022.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/023.webp, /rome/thumbnail/023-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/023.jpg, /rome/thumbnail/023-2x.jpg 2x" />
                <img src="/rome/thumbnail/023.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/024.webp, /rome/thumbnail/024-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/024.jpg, /rome/thumbnail/024-2x.jpg 2x" />
                <img src="/rome/thumbnail/024.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/025.webp, /rome/thumbnail/025-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/025.jpg, /rome/thumbnail/025-2x.jpg 2x" />
                <img src="/rome/thumbnail/025.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/026.webp, /rome/thumbnail/026-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/026.jpg, /rome/thumbnail/026-2x.jpg 2x" />
                <img src="/rome/thumbnail/026.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/027.webp, /rome/thumbnail/027-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/027.jpg, /rome/thumbnail/027-2x.jpg 2x" />
                <img src="/rome/thumbnail/027.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/028.webp, /rome/thumbnail/028-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/028.jpg, /rome/thumbnail/028-2x.jpg 2x" />
                <img src="/rome/thumbnail/028.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/029.webp, /rome/thumbnail/029-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/029.jpg, /rome/thumbnail/029-2x.jpg 2x" />
                <img src="/rome/thumbnail/029.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/030.webp, /rome/thumbnail/030-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/030.jpg, /rome/thumbnail/030-2x.jpg 2x" />
                <img src="/rome/thumbnail/030.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/031.webp, /rome/thumbnail/031-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/031.jpg, /rome/thumbnail/031-2x.jpg 2x" />
                <img src="/rome/thumbnail/031.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/032.webp, /rome/thumbnail/032-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/032.jpg, /rome/thumbnail/032-2x.jpg 2x" />
                <img src="/rome/thumbnail/032.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/033.webp, /rome/thumbnail/033-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/033.jpg, /rome/thumbnail/033-2x.jpg 2x" />
                <img src="/rome/thumbnail/033.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/034.webp, /rome/thumbnail/034-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/034.jpg, /rome/thumbnail/034-2x.jpg 2x" />
                <img src="/rome/thumbnail/034.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/035.webp, /rome/thumbnail/035-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/035.jpg, /rome/thumbnail/035-2x.jpg 2x" />
                <img src="/rome/thumbnail/035.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/036.webp, /rome/thumbnail/036-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/036.jpg, /rome/thumbnail/036-2x.jpg 2x" />
                <img src="/rome/thumbnail/036.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/037.webp, /rome/thumbnail/037-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/037.jpg, /rome/thumbnail/037-2x.jpg 2x" />
                <img src="/rome/thumbnail/037.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/038.webp, /rome/thumbnail/038-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/038.jpg, /rome/thumbnail/038-2x.jpg 2x" />
                <img src="/rome/thumbnail/038.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/039.webp, /rome/thumbnail/039-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/039.jpg, /rome/thumbnail/039-2x.jpg 2x" />
                <img src="/rome/thumbnail/039.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/040.webp, /rome/thumbnail/040-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/040.jpg, /rome/thumbnail/040-2x.jpg 2x" />
                <img src="/rome/thumbnail/040.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/041.webp, /rome/thumbnail/041-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/041.jpg, /rome/thumbnail/041-2x.jpg 2x" />
                <img src="/rome/thumbnail/041.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/042.webp, /rome/thumbnail/042-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/042.jpg, /rome/thumbnail/042-2x.jpg 2x" />
                <img src="/rome/thumbnail/042.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/043.webp, /rome/thumbnail/043-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/043.jpg, /rome/thumbnail/043-2x.jpg 2x" />
                <img src="/rome/thumbnail/043.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/044.webp, /rome/thumbnail/044-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/044.jpg, /rome/thumbnail/044-2x.jpg 2x" />
                <img src="/rome/thumbnail/044.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/045.webp, /rome/thumbnail/045-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/045.jpg, /rome/thumbnail/045-2x.jpg 2x" />
                <img src="/rome/thumbnail/045.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/046.webp, /rome/thumbnail/046-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/046.jpg, /rome/thumbnail/046-2x.jpg 2x" />
                <img src="/rome/thumbnail/046.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/047.webp, /rome/thumbnail/047-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/047.jpg, /rome/thumbnail/047-2x.jpg 2x" />
                <img src="/rome/thumbnail/047.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/048.webp, /rome/thumbnail/048-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/048.jpg, /rome/thumbnail/048-2x.jpg 2x" />
                <img src="/rome/thumbnail/048.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/049.webp, /rome/thumbnail/049-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/049.jpg, /rome/thumbnail/049-2x.jpg 2x" />
                <img src="/rome/thumbnail/049.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/050.webp, /rome/thumbnail/050-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/050.jpg, /rome/thumbnail/050-2x.jpg 2x" />
                <img src="/rome/thumbnail/050.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/051.webp, /rome/thumbnail/051-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/051.jpg, /rome/thumbnail/051-2x.jpg 2x" />
                <img src="/rome/thumbnail/051.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/052.webp, /rome/thumbnail/052-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/052.jpg, /rome/thumbnail/052-2x.jpg 2x" />
                <img src="/rome/thumbnail/052.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/055.webp, /rome/thumbnail/055-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/055.jpg, /rome/thumbnail/055-2x.jpg 2x" />
                <img src="/rome/thumbnail/055.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/056.webp, /rome/thumbnail/056-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/056.jpg, /rome/thumbnail/056-2x.jpg 2x" />
                <img src="/rome/thumbnail/056.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/060.webp, /rome/thumbnail/060-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/060.jpg, /rome/thumbnail/060-2x.jpg 2x" />
                <img src="/rome/thumbnail/060.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/061.webp, /rome/thumbnail/061-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/061.jpg, /rome/thumbnail/061-2x.jpg 2x" />
                <img src="/rome/thumbnail/061.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/062.webp, /rome/thumbnail/062-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/062.jpg, /rome/thumbnail/062-2x.jpg 2x" />
                <img src="/rome/thumbnail/062.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/063.webp, /rome/thumbnail/063-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/063.jpg, /rome/thumbnail/063-2x.jpg 2x" />
                <img src="/rome/thumbnail/063.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/064.webp, /rome/thumbnail/064-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/064.jpg, /rome/thumbnail/064-2x.jpg 2x" />
                <img src="/rome/thumbnail/064.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/066.webp, /rome/thumbnail/066-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/066.jpg, /rome/thumbnail/066-2x.jpg 2x" />
                <img src="/rome/thumbnail/066.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/rome/thumbnail/067.webp, /rome/thumbnail/067-2x.webp 2x" />
                <source srcSet="/rome/thumbnail/067.jpg, /rome/thumbnail/067-2x.jpg 2x" />
                <img src="/rome/thumbnail/067.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
